<template>
  <div>
    <div class="mb-4 text-muted">
      {{ $t("customerSubscriptionsText") }}
    </div>

    <b-card class="mb-4">
      <b-card-body class="d-flex p-1">
        <div class="d-flex flex-column mr-4">
          <small class="text-muted">{{ $t("activeSubscriptions") }}</small>
          <div>
            <div v-if="activeSubscriptions !== null">
              {{ activeSubscriptions }}
            </div>
            <b-spinner v-else label="Spinning" variant="dark" small></b-spinner>
          </div>
        </div>

        <div class="d-flex flex-column mr-4">
          <small class="text-muted">{{ $t("autoRenewal") }}</small>
          <div>
            <div v-if="autoRenewalSubscriptions !== null">
              {{ autoRenewalSubscriptions }}
            </div>
            <b-spinner v-else label="Spinning" variant="dark" small></b-spinner>
          </div>
        </div>

        <div class="d-flex flex-column mr-4">
          <small class="text-muted">{{ $t("volumeLevel") }}</small>
          <div>
            <fa-icon :icon="['fa-regular', 'fa-layer-group']" class="mr-1"></fa-icon
            >{{ correspondingVolumeLevel }}
          </div>
        </div>

        <div class="d-flex flex-column mr-4">
          <small class="text-muted">{{ $t("consumableLevel") }}</small>
          <div>
            <fa-icon :icon="['fa-regular', 'fa-layer-group']" class="mr-1"></fa-icon
            >{{ correspondingConsumableLevel }}
          </div>
        </div>

        <div class="d-flex flex-column mr-4" v-if="threeYearsCommit[0]?.commitment?.minimumQuantities[0]?.offerType">
          <small class="text-muted"> {{$t("minimumAmountSubscriptions")}} </small>
          <div>
            <fa-icon :icon="['fa-regular','fa-layer-plus']" class="mr-1"></fa-icon>{{threeYearsCommit[0].commitment?.minimumQuantities[0]?.quantity}}
          </div>
        </div>

        <div class="d-flex flex-column mr-4" v-if="threeYearsCommit[0]?.commitment?.minimumQuantities[1]?.offerType">
          <small class="text-muted">  {{$t("minimumAmountConsumables")}}  </small>
          <div>
            <fa-icon :icon="['fa-regular','fa-layer-plus']" class="mr-1"></fa-icon>{{threeYearsCommit[0].commitment?.minimumQuantities[1]?.quantity}}
          </div>
        </div>
      </b-card-body>
    </b-card>

    <OpenAcquisitionsComponent :customerId="customer.customerId"/>

    <CreateRenewalOrderComponent
      :customer="customer"
      :subscriptions="subscriptions"
      v-if="cotermDateInGracePeriod || cotermDateInGracePeriodOther"
    />

    <b-card no-body>
      <b-card-header class="p-3 d-flex justify-content-between">
        <b-input-group>
          <template #prepend>
            <b-input-group-text class="bg-white pr-0"
              ><fa-icon :icon="['fa-regular', 'fa-magnifying-glass']"></fa-icon
            ></b-input-group-text>
          </template>
          <b-input
            :placeholder="$t('searchByProductOrSubscriptionId')"
            class="border-left-0"
            v-model="filter"
          ></b-input>
          <b-form-select v-model="filterSelected" :options="filterOptions" class="col-3"></b-form-select>
        </b-input-group>
        <div v-b-popover.hover.top="!isBetween3And30DaysBeforeRenewal && $t('canNotAddLicenseForNextRenewal')">
          <b-button class="btn btn-dark ml-2" style="width: 250px!important;"  :disabled="!isBetween3And30DaysBeforeRenewal" v-on:click="isBetween3And30DaysBeforeRenewal && addSubscription()" >{{$t("addLicense")}}</b-button>
        </div>
      </b-card-header>

      <b-table
        ref="subscriptions"
        :items="filterSubscriptionsData"
        :fields="fields"
        class="mb-0"
        show-empty
        :empty-filtered-text="$t('emptyFilteredText')"
        :empty-text="$t('noSubscriptions')"
        sort-icon-left
      >
        <template #empty="scope">
          <div class="text-center font-italic">{{ scope.emptyText }}</div>
        </template>

        <template #emptyfiltered="scope">
          <div class="text-center font-italic">{{ scope.emptyFilteredText }}</div>
        </template>

        <template #cell(productInfo)="data">
          <span v-html="$options.filters.productNameWithoutLevel(data.item.productName)" :title="`${data.item.productName} (${data.item.offerId})`"></span><br />
          <small class="text-muted" v-b-tooltip.hover :title="$t('subscriptionId')">
            {{ data.item.subscriptionId }}
          </small><br />
          <small class="text-muted" v-b-tooltip.hover title="Offer-ID">
            {{ data.item.offerId }}
          </small>
        </template>

        <template #cell(quantity)="data">
          <div class="d-flex flex-column">
            {{ data.item.currentQuantity }}
            <small class="text-muted">
              <span v-if="data.item.autoRenewal.enabled"
                >{{ data.item.autoRenewal.renewalQuantity }} {{ $t("willBeRenewedOnAnniversaryDate") }}</span
              >
              <span v-else class="text-danger">{{ $t("willNotBeRenewedOnAnniversaryDate") }}</span>
            </small>
          </div>
        </template>

        <template #cell(usedQuantity)="data">
          <div class="d-flex flex-column" v-if="data.item?.usedQuantity">
            <small>{{ data.item.usedQuantity }} {{ $t("usedLicenses") }}</small>
            <small class="text-muted">
              <span v-if="(data.item?.currentQuantity - data.item?.usedQuantity) >= 0" :class="(data.item?.currentQuantity - data.item?.usedQuantity) > 0 && 'text-success'">
                {{ (data.item.currentQuantity - data.item.usedQuantity)  }} {{ $t("unUsedLicenses") }}
              </span>
            </small>
          </div>
          <div class="d-flex flex-column" v-else><small class="text-muted">{{$t("noData")}}</small></div>
        </template>

        <template #cell(creationDate)="data">
          <fa-icon :icon="['fa-regular', 'fa-clock']" class="mr-1"></fa-icon>{{ data.item.creationDate | date }}
          <template v-if="creditpackSubsciptions.includes(data.item.offerId)">
            <br><small>{{$t("expiry")}} </small>
            <small>{{data.item.renewalDate | date }}</small>
          </template>
        </template>

        <template #cell(status)="data">
          <div :class="statusClass(data.item.status)">
            <fa-icon :icon="['fa-regular', statusIcon(data.item.status)]" class="mr-1"></fa-icon
            >{{ data.item.status | subscriptionStatus }}
          </div>
        </template>

        <template #cell(actions)="data">
          <div class="d-flex justify-content-end">
            <b-button
              variant="outline-primary"
              size="sm"
              v-on:click="showAutoRenewalModal(data.item)"
              v-if="(data.item.status === '1000' || data.item.status === '1009') && !creditpackSubsciptions.includes(data.item.offerId)"
            >
              {{ $t("manageAutoRenewal") }}
            </b-button>
          </div>
        </template>
      </b-table>
      <b-card-footer id="footerAddButton" :class="!isBetween3And30DaysBeforeRenewal && 'no'" v-on:click="isBetween3And30DaysBeforeRenewal && addSubscription()">
        <div style="display:flex; justify-content: center; width: 100%;" >
          <div id="customPlus"><fa-icon style="color: light-gray;;" :icon="['fa-regular', 'fa-plus']" class="fa-xl" ></fa-icon></div>
          <p v-if="isBetween3And30DaysBeforeRenewal"> {{$t("addLicenseForNextRenewal")}} </p>
          <p v-else> {{$t("canNotAddLicenseForNextRenewal")}} </p>
        </div>
      </b-card-footer>
    </b-card>

    <b-modal
      ref="auto-renewal-modal"
      :title="$t('manageAutoRenewal')"
      :content-class="contentClass"
      :header-class="headerClass"
      :footer-class="footerClass"
      :body-class="bodyClass"
      no-close-on-esc
      no-close-on-backdrop
      v-on:cancel="clearAutoRenewalData()"
      v-on:hidden="clearAutoRenewalData()"
    >
      <p class="text-muted" v-html="$t('autoRenewalInfo')"></p>
      <b-form-checkbox v-model="autoRenewal.enabled" switch>
        {{ $t("autoRenewalIs")
        }}<span :class="autoRenewalStatusClass(autoRenewal.enabled)">{{
          autoRenewalStatusLabel(autoRenewal.enabled)
        }}</span>
      </b-form-checkbox>

      <div class="mt-2" v-if="autoRenewal.enabled">
        <hr />
        <p class="text-muted" v-html="$t('renewTheFollowingAmountInfo')"></p>
        <label>{{ $t("renewTheFollowingAmount") }}</label>
        <p class="text-muted">{{ $t("currentQuantity") }} {{ currentQuantity }}</p>
        <b-form-input type="number" v-model="autoRenewal.renewalQuantity" class="w-25" min="1"></b-form-input>
      </div>

      <template #modal-footer="{ cancel }">
        <b-button variant="outline-secondary" v-on:click="cancel()">
          {{ $t("abort") }}
        </b-button>

        <submit-button-component
          v-on:click="updateSubscriptionAutoRenewal()"
          :variant="'primary'"
          :state="submitButtonState"
          :label="$t('save')"
          :block="false"
        ></submit-button-component>
      </template>
    </b-modal>
  </div>
</template>

<script>
import SubmitButtonComponent from "../components/SubmitButtonComponent.vue";
import CreateRenewalOrderComponent from "../components/CreateRenewalOrderComponent.vue";
import OpenAcquisitionsComponent from "../components/OpenAcquisitionsComponent.vue";
import { functions, firestore } from "../plugins/firebase"
import { httpsCallable } from "firebase/functions";
import { query, collection, where, getDocs, and} from "firebase/firestore";
import moment from "moment";

export default {
  name: "CustomerSubscriptionsView",
  components: { SubmitButtonComponent, OpenAcquisitionsComponent, CreateRenewalOrderComponent },
  props: {
    customer: {
      type: Object,
      default: () => {
        return null;
      },
    },
    subscriptions: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  computed: {
    submitButtonState() {
      if (this.isSubmitting === true) {
        return "working";
      }

      return "";
    },
    isBetween3And30DaysBeforeRenewal() {
      if (this.customer.cotermDate === "") return false;

      const difference = moment.tz(this.customer.cotermDate, "America/Los_Angeles").diff(moment().tz("America/Los_Angeles").format("YYYY-MM-DD"), "days");

      if (difference < 30 && difference >= 3) {
        return true;
      }

      return false;
    },
    cotermDateInGracePeriod() {
      if (this.customer.cotermDate === "") return false;

      const difference = moment.tz(this.customer.cotermDate, "America/Los_Angeles").diff(moment().tz("America/Los_Angeles").format("YYYY-MM-DD"), "days");

      if (difference < -14 || difference > 0) {
        return false;
      }

      return true;
    },
    cotermDateInGracePeriodOther() {
      if (this.customer.cotermDate === "") return false;

      const difference = moment.tz(this.customer.cotermDate, "America/Los_Angeles").subtract(1, 'year').diff(moment().tz("America/Los_Angeles").format("YYYY-MM-DD"), "days");
console.log(`OTHER`,difference)
      if (difference < -14 || difference > 0) {
        return false;
      }

      return true;
    },
    activeSubscriptions() {
      let result = 0;

      this.subscriptions.filter((subscription) => {
        if (subscription.status === "1000") {
          result += subscription.currentQuantity;
        }
      });

      return result;
    },
    autoRenewalSubscriptions() {
      let result = 0;

      this.subscriptions.filter((subscription) => {
        if (subscription.autoRenewal.enabled) {
          result += subscription.autoRenewal.renewalQuantity;
        }
      });

      return result;
    },
    correspondingVolumeLevel() {
      let volumeLevel = "01";

      this.customer.discounts.forEach((discount) => {
        if (discount.offerType === "LICENSE") {
          volumeLevel = discount.level
        }
      });

      return volumeLevel;
    },
    correspondingConsumableLevel() {
      let consumableLevel = "T1";

      this.customer.discounts.forEach((discount) => {
        if (discount.offerType === "CONSUMABLES") {
          consumableLevel = discount.level
        }
      });

      return consumableLevel;
    },
  },
  data() {
    return {
      filterSubscriptionsData: [],
      filterSelected: "All",
      filterOptions: [
          { value: 1000, text: this.$t("active"), active: true },
          { value: 1008, text: this.$t("inactive") },
          { value: 1009, text: this.$t("nextRenewal") },
          { value: "All", text: this.$t("showAll") },
        ],
      filter: "",
      isSubmitting: false,
      fields: [
        {
          key: "productInfo",
          label: this.$t("subscription"),
          sortable: false,
        },
        {
          key: "quantity",
          label: this.$t("activeSubscriptions"),
          sortable: false,
        },
        {
          key: "usedQuantity",
          label: this.$t("usedSubscriptions"),
          sortable: false,
        },
        {
          key: "creationDate",
          label: this.$t("activationDate"),
          sortable: false,
        },
        {
          key: "status",
          label: this.$t("status"),
          sortable: false,
        },
        {
          key: "actions",
          label: "",
          sortable: false,
        },
      ],
      contentClass: ["border-0", "shadow-lg"],
      footerClass: ["border-0 bg-light p-4"],
      headerClass: ["border-0 bg-light px-4"],
      bodyClass: ["p-4"],
      subscriptionId: "",
      autoRenewal: {
        renewalQuantity: "",
        enabled: true,
      },
      currentQuantity: 0,
      threeYearsCommit: this.customer.benefits.filter(d => d.type === "THREE_YEAR_COMMIT"),
      creditpackSubsciptions: [],
    };
  },
  watch: {
    subscriptions() {
      this.$refs.subscriptions.refresh();
      this.isSubmitting = false;
      this.$refs["auto-renewal-modal"].hide();
      this.filterSubscriptions()
    },
    filterSelected() {
      this.filterSubscriptions()
    },
    filter() {
      this.filterSubscriptions()
    },
  },
  methods: {
    addVolumeLevelToOfferId(offerId) {
      if(offerId){
        return offerId.replace(/CA\d\d/, `CA${this.correspondingVolumeLevel}`).replace(/CAT\d/, `CA${this.correspondingConsumableLevel}`);
      } else {
        return offerId
      }
    },
    filterSubscriptions() {
      const result = [];
      this.subscriptions.forEach((subscription) => {
        this.isCreditpack(subscription.offerId)
        subscription.offerId = this.addVolumeLevelToOfferId(subscription.offerId)
        if (
          subscription.productName.toLowerCase().includes(this.filter.toLowerCase()) ||
          subscription.subscriptionId.toLowerCase().includes(this.filter.toLowerCase())
        ) {
          if(this.filterSelected == "All" || subscription.status == this.filterSelected ) {
            result.push(subscription);
          }
        }
      });
      this.filterSubscriptionsData = result;
      return;
    },
statusClass(status) {
      if (status === "1000") {
        return "text-success";
      } else if (status === "1002") {
        return "text-warning";
      } else if (status === "1009") {
        return "text-muted";
      } else if (status === "404") {
        return "text-muted";
      } else {
        return "text-danger";
      }
    },
    statusIcon(status) {
      if (status === "1000") {
        return "fa-circle-check";
      } else if (status === "1002") {
        return "fa-circle";
      } else if (status === "1009") {
        return "fa-hourglass";
      } else {
        return "fa-circle-xmark";
      }
    },
    autoRenewalStatusLabel(status) {
      if (status === true) {
        return this.$t("enabled");
      } else {
        return this.$t("disabled");
      }
    },
    autoRenewalStatusClass(status) {
      if (status === true) {
        return "text-success";
      } else {
        return "text-danger";
      }
    },
    clearAutoRenewalData() {
      this.autoRenewal.renewalQuantity = "";
      this.autoRenewal.enabled = true;
      this.subscriptionId = "";
      this.currentQuantity = 0;
    },
    showAutoRenewalModal(data) {
      this.autoRenewal.enabled = data.autoRenewal.enabled;
      this.subscriptionId = data.subscriptionId;
      this.currentQuantity = data.currentQuantity;

      this.$refs["auto-renewal-modal"].show();
    },
    async updateSubscriptionAutoRenewal() {
      this.isSubmitting = true;

      const updateSubscriptionAutoRenewal = httpsCallable(functions, "updateSubscriptionAutoRenewal");
      await updateSubscriptionAutoRenewal({
        customerId: this.$route.params.customerId,
        subscriptionId: this.subscriptionId,
        autoRenewal: this.autoRenewal,
      })
        .then(() => {
          this.isSubmitting = false;
          this.$emit("refreshSubscriptions");
        })
        .catch((error) => {
          this.$store.dispatch("alertError", {
            message: error.message,
            show: true,
            noFooter: false,
          });
          this.isSubmitting = false;
        });
    },
    checkUsedLicenses() {
      let check = false
      this.subscriptions.forEach( subscription => {
        if ((subscription?.currentQuantity - subscription?.usedQuantity) < 0) {
          check = true
        }
      })
      return check
    },
    isRenewalOlderThen30Days(renewal){
      const pastTime = new Date(renewal);
      pastTime.setFullYear(pastTime.getFullYear() - 1);

      const now = new Date();

      const thirtyDaysInMs = 30 * 24 * 60 * 60 * 1000;
      const timeDiffInMs = now.getTime() - pastTime.getTime();

      if(timeDiffInMs >= thirtyDaysInMs){
          // RenewalDate is older than 30 days
          return true
      }else{
          // RenewalDate is not older than 30 days
          return false
      }
    },
    async isCreditpack(manufacturerItemNumber) {
      const productsRef = collection(firestore, 'products');
      const ItIsCreditpack = query(productsRef,
        and(  where('manufacturerItemNumber', '==', manufacturerItemNumber),
              where('filters', 'array-contains', 'consumable'),
          )
        )

      const querySnapshot = await getDocs(ItIsCreditpack);
      if (!querySnapshot.empty && 
          (querySnapshot.docs[0].data().filters.includes('teams') ||
           querySnapshot.docs[0].data().filters.includes('enterprise')
         )) {
        this.creditpackSubsciptions.push(manufacturerItemNumber)
      }
      else {
        return(false)
      }
    },
    addSubscription() {
      this.$router.push({
        name: "AddSubscriptionSelectProductView",
        params: {
          customerId: this.customer.customerId,
        },
      });
    }
  },
  mounted(){
    this.filterSubscriptions()
  }
};
</script>
<style scoped>
#footerAddButton {
  height: 55px;
  background-color: white;
  color: #686868;
  transition: all.3s ease-in-out;
}
#footerAddButton p {
  color: #78787800;
  transition: all.3s ease-in-out;
  position: absolute;
}
#footerAddButton #customPlus svg {
  transition: all.3s ease-in-out;
  color: #787878;
}
#footerAddButton:hover {
  background-color: rgb(246, 246, 246);
  color: #787878;
  cursor: pointer;
}
#footerAddButton:hover p {
  color: #787878;
}
#footerAddButton:hover #customPlus svg {
  color: #78787800;
}
#customPlus {
  position: absolute;
  display: flex;
  justify-content: center;
  width: 100%;
}
#footerAddButton.no, #footerAddButton.no:hover{
  cursor: not-allowed;
}
</style>