export default {
  resellerReferenceId: "Your Reference-ID:",
  teamsLicenses: "Teams licenses",
  teamsLicensesProEditions: `Teams licenses<br />Pro editions`,
  enterpriseLicenses: "Enterprise licenses",
  enterpriseLicensesProEditions: `Enterprise licenses<br />Edition 4`,
  edition3: `Enterprise licenses<br />Edition 3`,
  orderNumberInputErrorText: "",
  customerRenewalOrderNumberText: `On this page you can set your own order number for the upcoming renewal of this customer.<br />The field will be emptied automatically after the renewal.`,
  renewalOrderNumber: `Order number for renewal`,
  renewalOrderNumberShort: `Renewal Order no.:`,
  consumableLevel: `Consumable level`,
  consumables: `Consumables`,
  signTransactions: `Sign Transactions`,
  creditPacksTeams: `Teams Credit Packs`,
  creditPacksEnterprise: `Enterprise Credit Packs`,
  noPermissionToOrder: `You do not have permission to order. Please contact your administrator to obtain authorization.`,
  currentQuantity: "Number of currently active licenses:",
  userReceivesEmail: "The user will now receive a confirmation email.",
  userAllowedToOrder: "Is this user allowed order?",
  userIsNotAllowedToOrder: "You do not have permission to order. Please contact your administrator.",
  yes: "Yes",
  no: "No",
  editions: "Editions",
  createUserEmailHint: "The email must not be used by any other user or the main account.",
  resetPasswordText: "You can now choose a new password for your Adobe Cloud Store access.",
  resendInvitationText: `The customer does not seem to have accepted the invitation to the Adobe CMP.<br />You can resend it if necessary.`,
  resendInvitation: "Resend invitation",
  missedRenewalText: "Easily create a new order so that your customer can continue to use the licenses.",
  missedRenewal: "Missed renewal?",
  createRenewal: "create Renewal",
  lateRenewal: "Catch up on renewal",
  cartIsEmpty: "Your cart is empty",
  newPassword: "New password",
  confirmNewPassword: "Confirm new password",
  editLoginCredentialsText: "On this page you can change your access data.",
  editLoginCredentials: "Login credentials",
  userManagement: "User management",
  createUser: "Add user",
  editUser: "Edit user",
  usersText: "On this page you can create user accounts for other employees in your company.",
  searchByUserName: "Search by name",
  noUsers: "There are no users",
  deleteUserText: "Are you sure you want to delete the user <strong>{firstName} {lastName}</strong>?",
  renewalOfferFileName: "acs-renewal-offer-",
  offerFileName: "acs-offer-",
  renewalOfferFooterText: "All offers subject to change • Subject to errors and price changes • The guarantee periods mentioned correspond to the respective manufacturer guarantees • The agreed delivery takes place subject to correct and timely delivery to us • Our general terms and conditions of sale and delivery apply",
  offerFrom: "Offer from:",
  netFee: "Net Fee",
  grossPrice: "Gross Price",
  vat: "Value Added Tax (%{taxRate}%)",
  exclTax: "excluding value added tax",
  posNo: "Pos. No.",
  customerRenewalOfferText: "On this page you can download an offer for an upcoming renewal as a PDF.",
  downloadRenewalOffer: "Download offer for renewal",
  news: "News",
  support: "Support",
  notifications: "Notifications",
  notificationsText: "On this page you can specify the email address to which we should send notifications about upcoming renewals and purchase requests.",
  imprintLink: "Imprint",
  privacyLink: "Privacy policy",
  termsAndConditionsComlineLink: "Terms and conditions {companyName}",
  termsAndConditionsAdobeLink: "Terms and conditions Adobe CMP",
  emailLabel: "Email",
  emailInputErrorText: "Invalid email",
  passwordLabel: "Password",
  forgotPasswordLink: "Forgot password",
  signInButton: "Log in",
  signUpLink: "Register",
  signUpLinkText: "Don't have an account yet?",
  forgotPasswordHeadline: "Reset password",
  forgotPasswordText:
    "If you've forgotten your password, you can reset it here. <br /> Enter the email address you used to sign in.",
  resetPasswordButton: "Reset password",
  signInViewLink: "Log in",
  signUpHeadline: "Registration",
  signUpText: "Are you a {companyName} customer?<br />Then register for the Adobe Cloud Store today.",
  accessDataHeadline: "Access data",
  accessDataEmailInfo: "Will be used later for login",
  confirmEmailLabel: "Confirm email address",
  confirmEmailInputErrorText: "The email addresses are not identical",
  companyData: "Company data",
  customerNumber: "Customer No.", 
  customerNumberIsNotRequired: "Customer number in your system. Not required.",
  comlineCustomernumberLabel: "{companyName} customer number",
  comlineCustomernumberInputErrorText: "Not a valid {companyName} customer number",
  companyLabel: "Company",
  afterwardsNotChangeableInfo: "Can't change afterwards",
  notChangeableInfo: "Not changable",
  companyInputErrorText: "The company name is invalid",
  addressLine1Label: "Street and house number",
  addressLine2Label: "Additional address information",
  addressLine2Info: "Optional",
  postalCodeLabel: "Postal code",
  postalCodeInputErrorText: "The postal code is invalid",
  cityLabel: "City",
  countryLabel: "Country",
  phoneNumberLabel: "Phone number",
  prefferredLanguageLabel: "Preferred language",
  companyLogo: "Company Logo",
  companyLogoUploadInformation: "Format: .png, .jpg, .gif | Dimesions: 512px x 512px | Size: (max) 5mb",
  companyLogoUploadInfo: "Your company logo for your offers.",
  selectImage: "seletect Image...",
  companyLogoUploadErrorDimensions: "The Image Dimensions are not 512px x 512px!",
  companyLogoUploadErrorSize: "The Image is to big.",
  companyLogoUploadErrorType: "Pleas use an Image!",
  contactHeadline: "Contact person",
  firstNameLabel: "First name",
  firstNameInputErrorText: "The first name is invalid",
  lastNameLabel: "Last name",
  lastNameInputErrorText: "The last name is invalid",
  legalHeadline: "Legal",
  termsAndConditionsLabel: "I accept the",
  signInLinkText: "Do you already have an account?",
  signUpButton: "Register",
  comlineContactsHeadline: "Contacts",
  comlineContactsText: "We gladly support you! Just contact us.",
  accountLabel: "Yout account",
  createOrder: "Create order",
  customers: "Customers",
  transferArchive: "Transfer archive",
  transfers: "Transfers",
  transfer: "Transfer",
  settings: "Settings",
  addLicenseForNextRenewal: "Add license for next renewal.",
  canNotAddLicenseForNextRenewal: "Licenses for next renewal can only be added between 30 and 3 days before renewal.",
  addLicense: "Add Renewal License",
  addForAFee: "add for a Fee",
  addLicenseText: 'This is not an order. The selected product will only be booked with the next renewal. The number can be changed at any time in the customer under "Licenses" or the license can be deactivated for the renewal.',
  contacts: "Contacts",
  language: "Language",
  help: "Help",
  faq: "FAQ",
  signout: "Sign out",
  save: "Save",
  addContact: "Add Contact",
  yourLimit: "Your limit",
  delete: "Delete",
  edit: "Edit",
  faqText: `
    On this page you will find answers to the most frequently asked questions.<br />
    Just click on the question to get the answer.`,
  furtherQuestions: "Do you have further questions?",
  contactUs: "No problem! Just contact us.",
  active: "Active",
  waitingForRenewal: "Waiting for Renewal",
  nextRenewal: "Preordered",
  pending: "Pending",
  inactive: "Inactive",
  leaved: "Account moved",
  statusOfCustomer: "Status of Customer.",
  inactiveInvalidAddress: "Inactive - Invalid Address",
  inactiveAccountBlocked: "Inactive - Account is blocked",
  inactiveCustomerAlreadyExists: "Inactive - Customer already exists with the same Company Name and primary admin",
  complete: "Complete",
  transferNotSubmitted: "Not yet submitted",
  open: "Open",
  failed: "Failed",
  canceled: "Canceled",
  cancel: "Cancel",
  failedDistributorInactive: "Failed - distributor is inactive",
  failedResellerInactive: "Failed - Reseller is inactive",
  failedCustomerInactive: "Failed - Customer is inactive",
  failedCustomerIdInavild: "Failed - Customer ID is invalid",
  error: "Error",
  success: "Success",
  priceInfo: "Price info",
  rrp: "RRP",
  limitWarning: `Your available limit is: {availableLimit}<br />
  Your limit is low, you may not be able to fulfill your order.`,
  activeSubscriptions: "Active subscriptions",
  usedSubscriptions: "unused Licenses",
  usedLicenses: "in use",
  unUsedLicenses: "available",
  volumeLevel: "Volume level",
  subscriptions: "Subscriptions",
  purchasePrices: "purchase prices",
  customerRequestedPurchaseHeadline: "Your Customer has requested a purchase.",
  customerRequestedPurchaseInfo: "Your customer has added at least one additional license via the Admin Console. For more information, please contact your customer or look for the mail \"A customer has requested a purchase\" in your email inbox. Please place a corresponding order within the next 7 days.",
  customerRequestedPurchaseText: "Please check your emails or get in contact with your customer.",
  customerRequestedAPurchase: "Your customer has requested this product for purchase!",
  noData: "No data available yet.",
  purchaseRequested: "purchase requested",
  requestedAt: "Requested at",
  messageUnusedLicenses:"Customer still has {quantity} unused licenses!",
  subscription: "Subscription",
  orderHistory: "Order history",
  anniversaryDateNotSet: "Has not yet been set",
  pieces: "pcs.",
  product: "Product",
  quantity: "Quantity",
  requestPrices: "Request prices",
  confirmSignUpMessage: `<strong>Thank you</strong><br /><br />Your registration will now be checked by a employee. You will receive a message after the check has been carried out.`,
  createCustomer: "Create a new customer",
  createCustomerInfoText: `On this page you can add a new customer to Adobe VIPMP. <br />
  <strong class = "text-body"> Attention: </strong> To transfer an existing VIP customer to the VIPMP, please click `,
  changeableInAdminConsoleInfo: "Can only be changed in the Adobe Admin Console",
  customerCreated: "The customer was created.",
  createOrderSuccessInfo: "Thank you for your order",
  create: "Create",
  newOrderFor: "New order for customer",
  bestPriceInfo: "We will find the best possible price for you.",
  bestPriceDeterminedInfo: "We have determined the best possible price for you.",
  summary: "Summary",
  customer: "Customer",
  price: "Price",
  totalPrice: "Total price",
  totalPriceRrp: "Total price (rrp)",
  margin: "Margin",
  manufacturerNumber: "Manufacturer number",
  orderNumber: "Order number",
  optional: "Optional",
  orderNow: "Order now",
  order: "Order",
  activeCustomers: "Active customers",
  activeCustomersCreateOrderInfo: "Here you can see your active customers for whom you can create orders.",
  browse: "Browse",
  emptyFilteredText: "There is no record that matches what you entered",
  noCustomers: "There are no customers",
  ordersInProgress: "Orders in progress",
  ordersInProgressText: "Here you can see the orders you have already started. You can continue or delete them.",
  noOrdersText: "There are no orders",
  abort: "Cancel",
  deleteOrderText: "Do you really want to delete this order?",
  numberOfProductsInCart: "You have <strong>{quantity}</strong> products in the shopping cart.",
  grid: "Grid",
  list: "List",
  customerOrdersText: "This is the customer's order history.",
  customerOrdersHint: `<strong class = "text-body"> Hint: </strong> Click on the order ID to see the positions.`,
  searchByOrderId: "Search by order ID",
  renewal: "Renewal",
  doYouWantTocancelThisOrder: `Do you want to cancel this order <strong>{cancelOrderId}</strong>?`,
  doYouWantTocancelThisLineItem: `Do you want to cancel the line item <strong>{lineItemIndex}</strong> in order <strong>{cancelOrderId}</strong>?`,
  pleaseEnterReason: "Please enter a reason",
  customerSubscriptionsText: "Here you can see all of the customer's subscriptions and manage them if necessary.",
  searchByProductOrSubscriptionId: "Search by product or subscription ID",
  noSubscriptions: "There are no subscriptions",
  subscriptionId: "Subscription ID",
  willBeRenewedOnAnniversaryDate: "will be renewed",
  willNotBeRenewedOnAnniversaryDate: "Won't be renewed",
  autoRenewal: "Auto renewal",
  manageAutoRenewal: "Manage auto renewal",
  shoppingCart: "Shopping cart",
  creationTime: "Time of creation",
  anniversaryDate: "Anniversary date",
  endDate: "End date",
  status: "Status",
  orderId: "Order ID",
  creationDate: "Creation Date",
  activationDate: "Activation date",
  type: "Type",
  customerId: "Customer ID",
  membershipId: "VIP Membership ID",
  transferId: "Transfer ID",
  submitTransfer: "Submit",
  transferVipSubscriptions: "Transfer customer from VIP to VIPMP",
  transfersText: `On this page you can see an overview of your transfers.`,
  searchByMembershipIdCusomterCustomerId: "Search by membership ID, customer or customer ID",
  searchByCustomerCustomerId: "Search by customer or customer ID",
  transfersText2: "On this page you can transfer a customer's eligible VIP licenses to the VIP Marketplace.<br />A form for obtaining the required approval from your end customer can be found <a href=\"/declaration_of_consent_data_transfer_adobe_certified_reseller.pdf\" target=\"_blank\">here</a>.",
  transfersCheckBoxText: `I am authorized to carry out this transfer. <br />
  <strong class = "text-danger"> Warning: </strong> This process cannot be undone!`,
  showEligibleSubscriptions: "Show eligible licenses",
  transferEligibleSubscriptions: "Transfer eligible licenses",
  transfersText3: "On this page you can see all licenses for a transfer to the VIP membership ID:: <b>%{membershipID}</b>",
  here: "here",
  customersText: `On this page you can see an overview of your customers and manage them if necessary. <br />
  You can use the <i>create a new customer</i> button to add a new customer to Adobe VIP Marketplace.`,
  pricePerYear: "Price per year",
  daysPerYear: "Days in year",
  daysUntilAnniversaryDate: "Days until anniversary date",
  createAccount: "Create Account",
  createAccountText: "You can now create your account for the Adobe Cloud Store.",
  confirmPassword: "Confirm password",
  requirements: "Requirements",
  requirementsText: `
  Min. length 8 characters <br />
  Min. 2 small letters <br />
  Min. 2 large letters <br />
  Min. 2 numbers`,
  passwordInsecure: "Insecure password",
  passwordsNotIdentical: "Passwords are not the same",
  youAlreadyHaveAnAccount: "Do you already have an account?",
  loginText: "Log in",
  youCanLoginWithYourNewPassword: "You can now log in with your new access data.",
  toTheLoginForm: "To the log in form",
  offer: "Offer",
  printOffer: "Download offer",
  newOfferForCustomer: "New offer for customer",
  systemErrorText: "Due to a system error at Adobe, there may currently be impairments. <br /> We ask for your understanding.",
  renewalOffer: "Offer for renewal",
  autoRenewalInfo: `<strong class="text-danger text-uppercase"> Warning </strong><br />If you deactivate the auto renewal, the license will no longer be automatically renewed on the anniversary date. <strong>This can lead to the loss of the license</strong>.`,
  autoRenewalIs: "The auto renewal is ",
  enabled: "enabled",
  disabled: "disabled",
  renewTheFollowingAmount: "Renew the following amount in the future",
  renewTheFollowingAmountInfo: `<strong class="text-danger text-uppercase">Warning</strong><br />
  If the renewal amount has been explicitly set, <strong>the renewal amount will not be increased or decreased by additional orders or cancellations</strong> (until the subscription becomes inactive).<br /><br />
  If the renewal amount at the time of renewal is higher than the current amount, the additional licenses will be included in the renewal. If it is lower, the licenses will be removed.<br /><br />
  If the renewal amount has not been explicitly set (blank field) the current amount will be renewed at the time of renewal. <strong>The renewal amount will be increased or decreased by additional orders or cancellations</strong>.`,
  updateCustomers: "Update customer",
  exportCustomerData: "Export customer data",
  showHiddenCustomers: "Show hidden customer",
  hideHiddenCustomers: "Hide hidden customer",
  hideCustomer: "Hide customer",
  showCostomer: "show customer",
  pleaseWait: "Please wait. This process can take some time.",
  furtherMailAddressesHeadline: "Further Mail Addresses",
  furtherMailAddresses: "Further Mail Addresses",
  furtherMailAddressesPlaceholder: "Add email",
  notofocationSettings: "Notification Settings",
  threeYCNotification: "3-years commit Notifications",
  threeYCNotificationAditionalInformation: "Here you can select when you want to receive notifications.",
  multiselectText: 'Hold down the <kbd class="notranslate" translate="no">ctrl</kbd> (Windows) or <kbd class="notranslate" translate="no">cmd</kbd> (Mac) button to select multiple items.',
  selectedDays: "selected Days",
  enterValidEMail: "Enter a valid Email...",
  notificationStatusOn: "is on",
  notificationStatusOff: "is off",
  customerStatusChangeNotification: "Customer state change",
  customerStatusChangeNotificationInformation: "Sends a mail if the Status of a Customer changes.",
  orderSuccessNotification: "Order notificaion",
  orderSuccessNotificationInformation: "Sends a mail if a order was placed successfully.",
  renewalSuccessNotification: "Renewal was successful",
  renewalSuccessNotificationInformation: "Sends a mail if a renewal was placed successfully.",
  renewalReminderNotification: "Renewalreminder",
  renewalReminderNotificationInformation: "Sends a mail at selected days prior to renewal.",
  deactivatedRenewalReminderNotification: "Renewalreminder (deactivated)",
  deactivatedRenewalReminderNotificationInformation:"Sends a mail at selected days prior to renewal for deactivated subscriptions.",
  maintenance: "Due to maintenance work, the Adobe Cloud Store is temporarily not available. Please check back later.",
  threeyearcommit: "3-year commit loyalty program",
  threeyearcommitInfo: "The 3-year commit loyalty program can <strong>not</strong> be combined with the Extended term paid-up-front.",
  minimumAmountSubscriptions: "Minimum amount subscriptions",
  minimumAmountConsumables: "Minimum amount consumables",
  customerNotIn3YC: "The customer is currently not in the 3-year commit loyalty program.",
  customerHasActive3yc: "The Customer is currently in the 3-year commit loyalty program.",
  customerIsIn3YCFromDateToDate: "The customer is in the 3-year commit loyalty program from {startDate} to {endDate}.",
  numberOfDaysTillEndOf3YC: "An extension is still possible for {numberOfDaysTillEnd} days.",
  extend3YC: "Renew 3-year commitment",
  extentionRequested: "An renewal was requested.",
  customerDeclinedExtention: "The customer declined the renewal.",
  customerAcceptedExtentionNextTermStartsAt: "The customer has agreed to the renewal. The next term begins on {startDate}.",
  waitingForApproval: `<strong>Waiting for confirmation from customer.</strong><br /><br />The customer has not yet accepted the invitation to the 3-year commit loyalty program.`,
  customerAccepted3YC: `<strong>The customer accept the invitation.</strong><br /><br />Make sure the required minimum quantities are met within the next 7 days in order for your customer to take advantage of the benefits.`,
  customerDeclined3YC: `<strong>The invitation was declined.</strong><br /><br />The customer declined the invitation to the 3-year commit loyalty program.`,
  customerNotIn3YCSendInvitation: "Here you can send an invitation to the 3-year commit loyalty program to the customer or increase the minimum quantity.",
  send3YCInvitation: "Send out a 3-year commit invitation",
  set3YCLevelInfo: `<p>Choose the level in which the customer should join the 3-year commit loyalty program.</p><p>The customer will receive an email so that they can accept the invitation.</p>`,
  extend3YCNow: "Send out a 3-year commit invitation",
  extendedTerm: "Extended term paid-up-front",
  threeYearsCommitMinQuantity: "Minimum is 10",
  extendedTermDescription: `Here you can assign the customer a custom anniversary date.<br />
  This must be between 1 and 3 years (Commercial) or 1 and 4 years (Education and Government) in the future.<br />
  The customer then pays the licenses in advance for the selected period.<br />
  After the anniversary date of the selected period, the customer's licenses will be renewed in the regular cycle.`,
  noAnniversaryDateSelected:"No anniversary date selected",
  anniversaryDateCannotBeChanged: "The anniversary date cannot be changed after the first order.",
  basicOption: "Volume Discount",
  threeYCOption: "3-year commit",
  expiry: "expiry",
  yourResellerId: "Your Reseller ID",
  signedInAs: "Signed in as",
  yourDashboard: "Your Dashboard",
  keyFactsAtAGlance: "All key facts at a glance.",
  noNewsAtTheMoment: `<p>There is currently no news.</p><strong>You are well informed!</strong>`,
  pending3ycInvitations: "Pending invitations to the 3-year commit loyalty program",
  noPending3ycInvitations: `<p>There are currently no pending invitations to the 3-year commit loyalty program.</p><strong>Good work!</strong>`,
  vipmpReleaseTimeline: "Adobe VIPMP Release Timeline",
  upcomingRenewals: "Upcoming renewals",
  today: "Today",
  noUpcomingRenewals: `<p>There are no renewals scheduled for {date}.</p><strong>Maybe tomorrow?</strong>`,
  newVersion: "A new version is available.",
  update: "Update",
  marketSegment: "Market segment",
  marketSubSegment: "Subsegment",
  changeableIfCustomerHasNoActiveSubscriptions: "Changeable if customer has no active subscriptions.",
  ignoreOrderReturn: "Ignore the return policy",
  ignoreOrderReturnText: "I am aware that the right to return products ordered in VIP Legacy expires upon migration.",
  expireOpenPas: "Expire purchase authorizations",
  expireOpenPasText: "I am aware that purchase authorizations that have already been created in the customer account will expire during migration.",
  purchaseRequests: "Purchase requests",
  showAll: "Show all",
  noPurchaseRequests: `<p>There are currently no open purchase requests.</p><strong>Good job!</strong>`,
  search: "Search",
  backToHomepage: "Back to homepage",
  cancelOrder: "Cancel order",
  timeZoneNoticePrice: "For technical reasons, prices are calculated based on the America/Los_Angeles time zone.",
  timeZoneNoticeDate: "For technical reasons, the date is set based on the America/Los_Angeles time zone.",
  transferCustomer: "Transfer customer",
  vipToVipmp: "VIP to VIPMP",
  resellerToReseller: "Reseller to Reseller",
  transferCustomerFromResellertoReseller: "Transfer customer from reseller to reseller",
  transferCustomerFromResellertoResellerInfo: "On this page you can transfer a customer from one reseller to another.",
  approvalCode: "Approval code",
  requestedBy: "Requested by",
  previewTransfer: "Preview transfer",
  requestedByInfo: "Email address of the person who requested the transfer.",
  adobeTechnicalProblems: "Due to a technical disruption at Adobe, the shop is currently only usable to a limited extent. Thank you for your understanding.",
  linkedMembership: "Linked Membership",
  requied: "Requied fields",
  linkedMembershipType: "Linked Membership Type",
  linkedMembershipName: "Name",
  id: "ID",
  standard: "Standard",
  cunsortium: "Cunsortium",
  linkedMemberShipDesciption: "Release Linked Membership:",
  knownCustomers: "Known linked customers:",
};
