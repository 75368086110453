<template>
  <div>
    <b-alert class="mt-4 mb-4 d-flex justify-content-between align-items-center" variant="warning" show>
      <div>
        <h5>{{ $t("missedRenewal") }}</h5>
        {{ $t("missedRenewalText") }}
      </div>
      <b-button variant="warning" @click="openModal()">
        {{ $t('createRenewal')}}
      </b-button>
    </b-alert>
    <!-- MODAL! -->
    <b-modal
      :id="'late-renewal'"
      ref="late-renewal"
      size="lg"
      :footer-class="footerClass"
      :header-class="headerClass"
      :body-class="bodyClass"
      :title="$t('lateRenewal')"
    >
      <table class="table mb-0" v-if="subscriptionDataEditable.length > 0 && !isWorking">
        <tbody>
          <!-- <pre>{{ subscriptionDataEditable }}</pre> -->
          <tr v-for="(item, index) in subscriptionDataEditable" :key="`row-${index}`">
            <td class="d-flex align-items-center">
              <div class="border rounded p-1 mr-3">
                <b-img-lazy :src="item.imageUrl" :alt="item.productName"></b-img-lazy>
              </div>
              <div>
                {{ item.productName | productNameWithoutLevel }}<br />
                <small class="text-muted" v-b-tooltip.hover :title="$t('manufacturerNumber')">
                  {{ item?.offerId }}
                </small>
              </div>
            </td>
            <td>
              <b-input
                type="number"
                v-model="item.currentQuantity"
                :max="getMaxQuantity(item?.offerId)"
                :min="0"
                style="width: 85px"
              ></b-input>
            </td>
          </tr>
        </tbody>
      </table>

      <template #modal-footer>
        <submit-button-component
          v-on:click="createOrder()"
          :state="submitButtonState"
          :label="$t('createRenewal')"
          :block="false"
          :variant="'primary'"
        ></submit-button-component>
      </template>
    </b-modal>
  </div>
</template>

<script>
import moment from "moment-timezone";
import SubmitButtonComponent from './SubmitButtonComponent.vue';
import { firestore } from "../plugins/firebase"
import { collection, addDoc, query, getDocs, writeBatch, where } from "firebase/firestore";

export default {
  name: "CreateRenewalOrderComponent",
  components: { SubmitButtonComponent },
  props: {
    profile: {
      type: Object,
      default: () => {
        return null;
      },
    },
    customer: {
      type: Object,
      default: () => {
        return null;
      },
    },
    subscriptions: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      isWorking: true,
      contentClass: ["border-0 shadow-lg"],
      footerClass: ["border-0 bg-light p-4"],
      headerClass: ["border-0 bg-light p-4"],
      bodyClass: ["d-flex p-4"],
      subscriptionDataEditable: []
    };
  },
  
  computed: {
    submitButtonState() {
      if (this.isWorking === true) {
        return "working";
      }

      return "";
    },
  },
  methods: {
    async openModal(){
      this.subscriptionDataEditable = []
      for await (const subscription of this.subscriptions){

        if(subscription.currentQuantity > 0) {
          this.subscriptionDataEditable.push({...subscription}) 
          await this.getImageForProduct(subscription.offerId)
        }
      }
      this.isWorking = false
      this.$refs['late-renewal'].show()
    },
    customerCheckCotermDate(customer) {
      const editedCustomer = customer;
      const cotermDateSplited = editedCustomer.cotermDate.split("-")
      const thisYear = moment.tz("America/Los_Angeles").year();
      
      editedCustomer.cotermDate = thisYear+"-"+cotermDateSplited[1]+"-"+cotermDateSplited[2];
      return customer
    }, 
    async createOrder() {
      this.isWorking = true;

      const allProducts = await this.getProducts();

      const products = [];
      this.subscriptionDataEditable.forEach((subscription) => {
        console.log("subscription.offerId", subscription.offerId)

        const filteredProducts = allProducts.filter((product) => product.manufacturerItemNumber === subscription.offerId);  
        console.log('filteredProducts', filteredProducts)
        if (filteredProducts.length > 0) {
          filteredProducts[0]["quantity"] = subscription.currentQuantity;
          filteredProducts[0]["subscriptionId"] = subscription.subscriptionId;

          filteredProducts[0].quantity > 0 && products.push(filteredProducts[0]);
        }
      });

      const parentDocRef = await addDoc(collection(firestore, "orders"), {
        finished: false,
        customer: this.customerCheckCotermDate(this.customer),
        deluxeOrder: null,
        creationDate: moment().tz("Europe/Berlin").format(),
        reseller: {
          companyProfile: this.$store.state.resellerData.companyProfile,
          resellerId: this.$store.state.resellerData.resellerId,
          customernumber: this.$store.state.resellerData.customernumber,
        },
        isRenewal: true,
        ttl: moment().tz("Europe/Berlin").add(7, "days").toDate(),
      });

      const batch = writeBatch(firestore);

      products.forEach((product) => {
        console.log("ONLY THIS IS RELEVANT", product)
        const docRef = collection(firestore, "orders", parentDocRef.id, "products");
        addDoc(docRef, product);
      });

      await batch.commit();

      this.isWorking = false;

      this.$router.push({
        name: "CreateOrderCheckoutView",
        params: {
          orderId: parentDocRef.id,
        },
      });
    },
    async getImageForProduct(manufacturerItemNumber) {
      const productsColRef = query(collection(firestore, "products"), where("manufacturerItemNumber", "==", manufacturerItemNumber));
      await getDocs(productsColRef)
      .then(res=>{
        this.subscriptionDataEditable.filter((subscription) => {
          return subscription.offerId == manufacturerItemNumber;
        })[0].imageUrl = res.docs[0].data().imageUrl;
        return 
      })
    },
    async getProducts() {
      const productsColRef = query(collection(firestore, "products"));
      const productsPromise = getDocs(productsColRef);
 
      const pricesColRef = collection(firestore, "resellers", this.$store.state.resellerData.firestoreId, "prices");
      const pricesPromise = getDocs(pricesColRef);

      const results = await Promise.all([productsPromise, pricesPromise]).catch((error) => {
        console.error(error);
      });

      if (results[0].empty || results[1].empty) {
        this.$store.dispatch("alertError", {
          message: "Es wurden keine Produkte gefunden.",
          show: true,
          noFooter: false,
        });  
        return;
      }

      const products = [];
      results[0].forEach((product) => {
        products.push(product.data());
      });

      const prices = [];
      results[1].forEach((price) => {
        prices.push(price.data());
      });

      const productsUnique = [];

      products.forEach((product) => {
        const itemNumberObject = this.$options.filters.itemNumberObject(product.itemNumber);

        const productObject = {
          productCode: itemNumberObject.productCode,
          language: itemNumberObject.language,
          productType: itemNumberObject.productType,
          filters: product.filters,    
          imageUrl: product?.partnerData?.imageUrl || product.imageUrl,
          productName: product.productName,
          quantity: product.quantity,
          prices: [],
          manufacturerItemNumber: product.manufacturerItemNumber,
        };

        productsUnique.push(productObject);
      });

      const allPrices = [];

      products.forEach((product) => {
        const itemNumberObject = this.$options.filters.itemNumberObject(product.itemNumber);
        const matchedPrice = prices.filter((price) => product.itemNumber === price.itemNumber);
        console.log("matchedPrice", matchedPrice)
        const priceObject = {
          price: matchedPrice[0].price,
          itemNumber: matchedPrice[0].itemNumber,
          itemNumberObject: itemNumberObject,
          manufacturerItemNumber: product.manufacturerItemNumber,
          endcustomerSalesPrice: product?.partnerData?.endcustomerSalesPrice ? product.partnerData.endcustomerSalesPrice : product.endcustomerSalesPrice,
          imputedCost: product?.partnerData?.imputedCost ? product.partnerData.imputedCost : product.imputedCost,
        };

        allPrices.push(priceObject);
      });

      productsUnique.forEach((product, index) => {
        const matchedPrices = allPrices.filter(
          (price) =>
            product.language === price.itemNumberObject.language &&
            product.productCode === price.itemNumberObject.productCode && 
            product.productCode === price.itemNumberObject.productCode
        );
        productsUnique[index].prices = matchedPrices;
      });

      return productsUnique;
    },
    clArtNoObject(itemNumber) {
      const itemNumberChunks = itemNumber.split("-");
      const artNrSplittet = itemNumberChunks[3].split("");
      return {
        product: artNrSplittet[0] + artNrSplittet[1] + artNrSplittet[2] + artNrSplittet[3],
        volumeLevel: parseInt(artNrSplittet[4]) + 1,
        language: artNrSplittet[5],
        productType: itemNumberChunks[2],
      };
    },
    getMaxQuantity(itemnumber) {
      return this.subscriptions.find((subscription) => subscription.offerId === itemnumber).currentQuantity;
    }
  },
}
</script>